<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>MARCA</label>
      <input type="text" class="form-control" v-model="name" required />
    </div>
    <app-button-submit @click="save()"></app-button-submit>
  </form>
</template>

<script>
import { GeneralService } from "../GeneralService";
export default {
  data: () => ({ name: "", id: undefined }),
  methods: {
    save() {
      GeneralService.saveBrand(this.$data).then((res) =>
        this.$emit("submitted", res)
      );
    },
    loadReg(reg) {
      this.name = reg.name;
      this.id = reg.id;
    },
    reset() {
      this.name = "";
      this.id = "";
    }
  }
};
</script>

<style></style>
